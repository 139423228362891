import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentButton.figmaUrl.ios} codeUrl={checklists.componentButton.codeUrl.ios} checklists={checklists.componentButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Buttons are used to initiate an action or trigger the user to open a link.`}</p>
    <p>{`Use Legion Button styles for actions in forms, dialogs, and more with support lots type and attribute for costumize. Legion Button have 4 variants component: `}<strong parentName="p">{`Solid, Outline, Soft, Transparent`}</strong></p>
    <h3>{`Usage With Theme`}</h3>
    <p><strong parentName="p">{`Available themes: ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</strong></p>
    <p><strong parentName="p">{`Solid`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Outline`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Soft`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Tranparent`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <h3>{`Usage Without Theme`}</h3>
    <p><strong parentName="p">{`Solid`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`SolidBtn(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Outline`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`OutlineBtn(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Soft`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`SoftBtn(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <p><strong parentName="p">{`Transparent`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`TransparentBtn(
  title: "Button"
) {
  // Action Handler
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Solid`}</h2>
    <h3>{`Solid Responsiveness`}</h3>
    <p>{`This responsive button or boolean attribute for user to choose the button responsively to the screen / layout or not.`}</p>
    <p><strong parentName="p">{` Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Not Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-not-responsive.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
)
.variant(size: .medium, responsive: false)
`}</code></pre>
    <h3>{`Solid Size`}</h3>
    <p>{`This size text button attribute for user to choose size text inside the button.`}</p>
    <p><strong parentName="p">{` Large `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-large.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
)
.variant(size: .large, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Medium `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Small `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-small.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button"
)
.variant(size: .small, responsive: true)
`}</code></pre>
    <h3>{`Solid Icon`}</h3>
    <p>{`This icon attribute for user to choose the icon position inside the button.`}</p>
    <p><strong parentName="p">{` Left Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button",
  leftImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Right Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-right-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button",
  rightImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Icon Only `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "",
  centerImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <h3>{`Solid Disabled`}</h3>
    <p>{`This code sample demonstrates how to modify disable of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button",
)
.disableInteraction(true)
`}</code></pre>
    <h3>{`Solid Show Activity`}</h3>
    <p>{`This code sample demonstrates how to modify show activity of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-solid-show-activity.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSolidButton(
  title: "Button",
)
.showProgressView(true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Outline`}</h2>
    <h3>{`Outline Responsiveness`}</h3>
    <p>{`This responsive button or boolean attribute for user to choose the button responsively to the screen / layout or not.`}</p>
    <p><strong parentName="p">{` Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Not Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-not-responsive.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
)
.variant(size: .medium, responsive: false)
`}</code></pre>
    <h3>{`Outline Size`}</h3>
    <p>{`This size text button attribute for user to choose size text inside the button.`}</p>
    <p><strong parentName="p">{` Large `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-large.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
)
.variant(size: .large, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Medium `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Small `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-small.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button"
)
.variant(size: .small, responsive: true)
`}</code></pre>
    <h3>{`Outline Icon`}</h3>
    <p>{`This icon attribute for user to choose the icon position inside the button.`}</p>
    <p><strong parentName="p">{` Left Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button",
  leftImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Right Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-right-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button",
  rightImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Icon Only `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "",
  centerImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <h3>{`Outline Disabled`}</h3>
    <p>{`This code sample demonstrates how to modify disable of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button",
)
.disableInteraction(true)
`}</code></pre>
    <h3>{`Outline Show Activity`}</h3>
    <p>{`This code sample demonstrates how to modify show activity of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-outline-show-activity.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNOutlineButton(
  title: "Button",
)
.showProgressView(true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Soft`}</h2>
    <h3>{`Soft Responsiveness`}</h3>
    <p>{`This responsive button or boolean attribute for user to choose the button responsively to the screen / layout or not.`}</p>
    <p><strong parentName="p">{` Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Not Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-not-responsive.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
)
.variant(size: .medium, responsive: false)
`}</code></pre>
    <h3>{`Soft Size`}</h3>
    <p>{`This size text button attribute for user to choose size text inside the button.`}</p>
    <p><strong parentName="p">{` Large `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-large.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
)
.variant(size: .large, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Medium `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Small `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-small.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button"
)
.variant(size: .small, responsive: true)
`}</code></pre>
    <h3>{`Soft Icon`}</h3>
    <p>{`This icon attribute for user to choose the icon position inside the button.`}</p>
    <p><strong parentName="p">{` Left Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button",
  leftImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Right Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-right-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button",
  rightImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Icon Only `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "",
  centerImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <h3>{`Soft Disabled`}</h3>
    <p>{`This code sample demonstrates how to modify disable of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button",
)
.disableInteraction(true)
`}</code></pre>
    <h3>{`Soft Show Activity`}</h3>
    <p>{`This code sample demonstrates how to modify show activity of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-soft-show-activity.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSoftButton(
  title: "Button",
)
.showProgressView(true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Transparent`}</h2>
    <h3>{`Transparent Responsiveness`}</h3>
    <p>{`This responsive button or boolean attribute for user to choose the button responsively to the screen / layout or not.`}</p>
    <p><strong parentName="p">{` Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Not Responsive `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-not-responsive.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
)
.variant(size: .medium, responsive: false)
`}</code></pre>
    <h3>{`Transparent Size`}</h3>
    <p>{`This size text button attribute for user to choose size text inside the button.`}</p>
    <p><strong parentName="p">{` Large `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-large.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
)
.variant(size: .large, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Medium `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
)
.variant(size: .medium, responsive: true)
`}</code></pre>
    <p><strong parentName="p">{` Small `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-small.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button"
)
.variant(size: .small, responsive: true)
`}</code></pre>
    <h3>{`Transparent Icon`}</h3>
    <p>{`This icon attribute for user to choose the icon position inside the button.`}</p>
    <p><strong parentName="p">{` Left Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button",
  leftImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Right Icon `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-right-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button",
  rightImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <p><strong parentName="p">{` Icon Only `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "",
  centerImage: Image(systemName: "square.and.arrow.down")
)
`}</code></pre>
    <h3>{`Transparent Disabled`}</h3>
    <p>{`This code sample demonstrates how to modify disable of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button",
)
.disableInteraction(true)
`}</code></pre>
    <h3>{`Transparent Show Activity`}</h3>
    <p>{`This code sample demonstrates how to modify show activity of the button :`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-button-transparent-show-activity.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNTransparentButton(
  title: "Button",
)
.showProgressView(true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user triggers the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title for the button’s title, that describes the purpose of the button’s `}<inlineCode parentName="td">{`action`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Put the image in the left position in the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Put the image in the right position in the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`centerImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Put the image in the center position of the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`largeTextFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the font on the title button is in large size.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bodyLargeSemibold`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mediumTextFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the font on the title button is in medium size.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bodySmallSemibold`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`smallTextFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the font on the title button is in small size.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`captionLargeSemibold`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tintBtnColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To change the tint color of button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`depending on the variant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tintPressedBtnColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the tint color on the button when pressed state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`depending on the theme`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultBtnColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To change the tint color of button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`depending on the variant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pressedBtnColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the background color of the button when the button is in pressed state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`depending on the variant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cornerRadius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change corner radius value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`List of viewModifier`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`viewModifier`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button can be modified the size (large, medium, or small) of the title label and also responsive or not.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`size and responsive`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disableInteraction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A view that controls whether users can interact with this view.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`showProgressView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds a  condition if there is interaction from the user and want to change the button to a loading state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`isShow`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      